
import Vue from 'vue';

import numberFormater from 'Mixins/numberFormater';
import {
    getPoolInfo,
    MANAGE_LIQUIDITY_TYPES,
    SET_A_ASSET,
    SET_B_ASSET, SET_HAS_OPENED_POSITION,
    SET_MANAGE_LIQUIDITY_TYPE,
    SET_NETWORK,
    SET_PLACEMENT,
    SET_POOL_FEE,
    setWrappedAssets,
} from 'Store/v2/DefiLiquidityPools';
import ApiError from 'Entities/ApiError';
import DexPosition from 'Entities/privatePresenter/DexPosition';
import { calculatePrecision } from 'Lib/utils/quotationAssetPrecisionCalculator';
import InternalUserResponse from 'Entities/userLoginHistory/InternalUserResponse';
import Button from 'Control/Button.vue';
import { PlacementsWithCapsules, PlacementCapsules } from 'Store/v2/Defi';

interface Data {
    calculatePrecision: any;
    PlacementsWithCapsules: any;
    PlacementCapsules: any;
}

interface Methods {
    quotedAmount: (data: number) => number;
    openManageLiquidityPage: (isRemoving: boolean) => void;
}

interface Computed {
    quoteSymbol: string;
    quotationAssetSymbol: string;
    currentPoolsPositions: DexPosition[];
    hasPoolPosition: boolean;
    currentUser: InternalUserResponse | undefined;
    isKycVerified: boolean;
}

export default Vue.extend<Data, Methods, Computed, any>({
    components: {
        Button,
    },
    props: {
        pool: {
            type: undefined,
            required: true,
        },
        usdQuotations: {
            type: undefined,
            default: undefined,
        },
    },
    mixins: [numberFormater],
    data() {
        return {
            calculatePrecision,
            PlacementsWithCapsules,
            PlacementCapsules,
        };
    },
    computed: {
        currentUser() {
            return this.$store.state.User.currentUser;
        },
        isKycVerified() {
            if (!this.currentUser) {
                return false;
            }
            return this.currentUser.kycStatus === 'Verified';
        },
        quoteSymbol() {
            return this.$store.getters['Assets/GET_QUOTATION_ASSET_CHARACTER'];
        },
        quotationAssetSymbol() {
            return this.$store.getters['Assets/GET_QUOTATION_ASSET_SYMBOL'];
        },
        currentPoolsPositions() {
            return this.$store.state.DefiLiquidityPools.poolsPositions;
        },
        hasPoolPosition() {
            const position = this.currentPoolsPositions.find((p) => p.aAssetSymbol === this.pool.asset0Symbol
                && p.bAssetSymbol === this.pool.asset1Symbol
                && p.blockchainName === this.pool.blockchainName
                && p.placementName === this.pool.placementName);
            return !!position;
        },
    },
    methods: {
        quotedAmount(amount) {
            if (this.quotationAssetSymbol === 'USD') {
                return Number(amount.toFixed(this.calculatePrecision(this.quotationAssetSymbol)));
            }
            return this.usdQuotations ? Number((amount * this.usdQuotations.find((q) => q.assetPairSymbol.indexOf(this.quotationAssetSymbol) !== -1)!.rate).toFixed(this.calculatePrecision(this.quotationAssetSymbol))) : 0;
        },
        async openManageLiquidityPage(isRemoving) {
            try {
                this.$store.commit(SET_MANAGE_LIQUIDITY_TYPE(isRemoving ? MANAGE_LIQUIDITY_TYPES.remove : MANAGE_LIQUIDITY_TYPES.add));
                this.$store.commit(SET_NETWORK(this.$store.state.Defi.ui.blockchain));
                this.$store.commit(SET_PLACEMENT(this.$store.state.Defi.ui.placement));
                this.$store.commit(SET_A_ASSET(this.pool.asset0Symbol));
                this.$store.commit(SET_B_ASSET(this.pool.asset1Symbol));
                this.$store.commit(SET_POOL_FEE(this.pool.commissionRate));
                this.$store.commit(SET_HAS_OPENED_POSITION(this.hasPoolPosition));
                await this.$store.dispatch(getPoolInfo(undefined));
                await this.$store.dispatch(setWrappedAssets(undefined));
                await this.$router.push('/Defi/ManageLiquidity').catch(() => { /* navigation error */ });
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during getting pool info, please try again later');
                }
            }
        },
    },
});
